import React, { useState, useRef, useEffect } from 'react'
import { navLinks, icon } from '../constants';
import { Link } from 'react-router-dom'

const Meunbtn = () => {

    {/* navbar button state */ }
    const [menu, setMenu] = useState(false)
    const [rotate, setRotate] = useState("none")
    const [visible, setVisible] = useState("invisible delay-500")
    const [fadeout, setFadeout] = useState("none")
    const hover = "hover:xx:rotate-90 hover:sm:gap-[2.4px] hover:sm:w-[23px] hover:sm:h-[23px]"

    {/* click outside the box reference */ }
    const ref = useRef(null);

    {/* navbar button controller */ }
    const menuClick = () => {
        setMenu(!menu)
        setRotate(menu ? "none" : "rotate-135")
        setVisible(menu ? "invisible delay-500" : "none")
        setFadeout(menu ? "animate__animated animate__fadeOutRight" : "animate__animated animate__fadeInRight")
    }

    {/* click outside the box controller */ }
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setMenu(false)
                setRotate("none")
                setVisible("invisible delay-500")
                setFadeout("animate__animated animate__fadeOutRight")
            }
        }

        {/* click outside the box event listener */ }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    return (
        <div ref={ref} className="z-[99] w-auto sm:w-[500px] h-full m-auto mr-[15px] xs:mr-[27px] sm:mr-[45px] flex">

            {/* laptop version navbtn */}
            <div className="w-auto sm:w-[500px] h-full mr-8 my-auto sm:flex justify-between items-center hidden sm:visible">

                {/* home / about / work / contact  */}
                {navLinks.map((item) =>
                    <a href={`#${item.id}`} key={item.id} className={`${fadeout} ${visible}`}>
                        <div className='z-[99] font-roboto font-medium tracking-tighter text-sm text-light group'>
                            <span className='text-main'>{item.num}</span> <span className="group-hover:text-main group-hover:border-b-2">{item.title}</span>
                        </div>
                    </a>
                )}

                {/* resume */}
                <Link to="https://www.canva.com/design/DAFaF4cU0p4/bqPye79ULVIH6MZvyVv4NQ/view?utm_content=DAFaF4cU0p4&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink" className={`z-[50] ${fadeout} ${visible}`} target="_blank">
                    <div className='font-poppins font-semibold text-sub text-xs p-[1.6px] px-1.5 border-[3px] border-sub hover:bg-sub hover:text-white rounded-md'>RESUME</div>
                </Link>

                {/* contact */}
                {icon.map((item) => (
                    <Link to={item.link} key={item.id} className={`${fadeout} ${visible} text-xl text-light py-2 flex flex-row items-center hover:text-main`} target="_blank">
                        {item.iclass}
                    </Link>
                ))
                }

            </div>

            {/* button logo */}
            <div className="w-auto h-full flex" onClick={menuClick}>
                <div className={`${rotate} ${hover} gap-[1.7px] w-[21.5px] h-[21.5px] m-auto grid grid-cols-2 ease-in-out duration-500`}>
                    <div className={`w-[10px] h-[10px] border-[3px] border-main ease-in-out rounded-sm`} />
                    <div className={`w-[10px] h-[10px] border-[3px] border-main ease-in-out rounded-sm`} />
                    <div className={`w-[10px] h-[10px] border-[3px] border-main ease-in-out rounded-sm`} />
                    <div className={`w-[10px] h-[10px] border-[3px] border-main ease-in-out rounded-sm`} />
                </div>
            </div>

            {/* mobile version navbtn */}
            <div className={`absolute top-[44px] xs:top-[55px] right-0 w-[150px] min-h-screen py-3 flex flex-wrap justify-center rounded-md bg-blur shadow-lg backdrop-blur-md ring-1 ring-black ring-opacity-5 ${fadeout} ${visible} sm:invisible`}>

                <div role="menu" aria-orientation="vertical" aria-labelledby="options-menu">

                    {/* home / about / work / contact  */}
                    {navLinks.map((item) =>
                        <a href={`#${item.id}`} key={item.id} className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                            <div className='z-[99] font-roboto font-medium tracking-tighter text-sm text-light group'>
                                <span className='text-main'>{item.num}</span> <span className="group-hover:text-main group-hover:border-b-2">{item.title}</span>
                            </div>
                        </a>
                    )}

                    {/* resume */}
                    <Link to="https://www.canva.com/design/DAFaF4cU0p4/bqPye79ULVIH6MZvyVv4NQ/view?utm_content=DAFaF4cU0p4&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink" className={`z-[50] ${fadeout} ${visible}`} target="_blank">
                        <span className='font-poppins font-semibold text-sub text-xs p-[1.6px] px-1.5 border-[3px] border-sub hover:bg-sub hover:text-white rounded-md ease-in-out block'>RESUME</span>
                    </Link>

                    {/* contact */}
                    <div className='w-auto h-auto flex py-2 flex-col text-light items-center text-xl'>

                        {icon.map((item) => (
                            <Link to={item.link} key={item.id} className="py-2 hover:text-main" target="_blank">
                                {item.iclass}
                            </Link>
                        ))
                        }

                    </div>

                </div>
            </div>

        </div>
    )
}

export default Meunbtn