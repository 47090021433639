import React from 'react'
import Title from '../../../../components/Title'
import { image } from '../../../../constants'
import Navbar from '../../../../components/Navbar'

const Logo = () => {
  return (
    <div>
      <Navbar work={true} path={"/graphic"} />

      <div className="mt-6 xs:mt-11 sm:mt-16 m-auto flex flex-wrap justify-center items-center w-[87%] animate__animated animate__fadeIn ease-in-out duration-500">

        <Title title={"Logo Design"} subtitle={"a collection of projects"} />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center justify-center max-w-[1250px] mx-10 md:mx-52 relative ease-in-out duration-300">

          <img className="w-full h-full col-span-1 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.logo[0]} />
          <img className="w-full h-full col-span-1 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.logo[1]} />
          <img className="w-full h-full col-span-1 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.logo[2]} />
          <img className="w-full h-full col-span-1 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.logo[3]} />

        </div>


      </div>
    </div>
  )
}

export default Logo