import React from 'react'
import Title from '../../../../components/Title'
import { image } from '../../../../constants'
import Navbar from '../../../../components/Navbar'

const Florian = () => {
  return (
    <div>
      <Navbar work={true} path={"/graphic"} />

      <div className="mt-6 xs:mt-11 sm:mt-16 m-auto flex flex-wrap justify-center items-center w-[87%] animate__animated animate__fadeIn ease-in-out duration-500">

        <Title title={"Florian"} subtitle={"a collection of projects"} />

        <div className="grid grid-cols-4 md:grid-cols-4 gap-4 items-center justify-center max-w-[1450px] mx-6 md:mx-28 relative">

          <img className="w-full h-full col-span-4 row-span-2 sm:col-span-2 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.florian[0]} />
          <img className="w-full h-full col-span-4 row-span-2 sm:col-span-2 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.florian[5]} />

          <img className="w-full h-full col-span-2 row-span-2 sm:col-span-1 sm:row-span-1 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.florian[1]} />
          <div className="w-full h-full col-span-2 row-span-1 sm:col-span-1 sm:row-span-1 rounded-lg flex justify-center items-center">
            <p className="z-[1] w-full p-0 sm:p-4 rounded-lg text-sm text-light drop-shadow-lg text-justify tracking-tight max-h-[168px] ease-in-out duration-300">
              The objective of this campaign is to promote the handmade bouquet brand Florian. To achieve this, we will offer photo shoots, customized poster designs, logo creation, and a slogan.
            </p>
          </div>

          <img className="w-full h-full col-span-1 row-span-1 sm:col-span-1 sm:row-span-1 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.florian[3]} />
          <img className="w-full h-full col-span-1 row-span-1 sm:col-span-1 sm:row-span-1 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.florian[4]} />

        </div>


      </div>
    </div>
  )
}

export default Florian