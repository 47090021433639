const Home = () => {

  return (
    <div className="mt-20 sm:mt-40 xs:mb-32 md:mb-10 pt-7 h-auto w-auto lg:w-full flex flex-wrap md:flex-none xs:h-[460px] justify-center  animate__animated animate__fadeIn ease-in-out duration-500 relative">

      {/* home section container */}
      <div className="w-full md:w-96 h-auto m-2 md:ml-32 flex flex-wrap justify-center relative ease-in-out duration-300">

        {/* title + context */}
        <h1 id="home" className="font-poppins w-full h-auto md:w-96 text-[2.9rem] xs:text-[3.7rem] sm:text-[6rem] md:text-[7rem] xs:leading-[110px] tracking-tight italic text-mainsub font-bold text-center md:text-start ease-in-out duration-300">Hello I’m Ian</h1>
        <p className="font-poppins w-[73%] md:w-96 h-auto font-medium sm:font-normal text-[0.5rem] xs:text-sm py-10 xs:py-10 xs:pt-6 xs:pb-10 xs:leading-[24px] tracking-tight text-light text-start md:text-justify ease-in-out duration-300">I enjoy creating frontend products that are both solid and scalable, with exceptional user experiences.</p>

        {/* 100project / 3yesrs */}
        <img className="w-auto h-20 mr-12 hidden md:block" src={process.env.PUBLIC_URL + "/img/about/100+.png"} alt="" />
        <img className="w-auto h-20 hidden md:block" src={process.env.PUBLIC_URL + "/img/about/3years.png"} alt="" />

      </div>

      {/* 3Dpng */}
      <img className="w-auto xs:max-h-[26rem] md:max-h-[27rem] xs:py-3 ease-in-out duration-300 scale-125 xs:scale-100" src={process.env.PUBLIC_URL + "/img/home/homepage-01.png"} alt="" />

      {/* background */}
      <div className="w-full h-full absolute top-0 left-0 overflow-x-clip">
        <div className="z-[-10] w-[50rem] h-[50rem] blur-2xl bg-gradient-to-bl border-white border-[0.2px] from-[#FFD01488] from-10% to-[#FFD01411] to-85% shadow-lg rounded-full top-[0%] left-[35%] xs:top-[0%] xs:left-[60%]  md:top-[-10%] md:left-[64%] absolute ease-in-out duration-200 animate-ping" />
      </div>

    </div>

  )
}

export default Home