import React from 'react';
import { Link } from 'react-router-dom';
import Meunbtn from './Meunbtn';
import Cancel from './Cancel';

function Navbar(props) {

   // go to top
  const handleClick = () => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 200);
  };

  return (
    <div className="z-[99] h-[42px] xs:h-[53px] w-full flex items-center justify-center fixed">

      {/* contact container */}
      <div className="h-full w-full xs:min-w-[500px] max-w-[1100px] flex justify-between items-center ease-in-out duration-300">

      {/* conditional rendering for a home logo or cancel button */}
      {props.work ? <Cancel path={props.path} id={"#work"} /> :
          <Link to="/" className="z-[99] animate__animated animate__fadeIn ease-in-out duration-500" onClick={handleClick}>
            <img className="z-[99] min-w-[29px] h-[29px] xs:w-[35px] xs:h-[35px] ml-[25px] xs:ml-[35px] sm:ml-[45px]" src={process.env.PUBLIC_URL + "/img/logo-01.png"} />
          </Link>
        }

      {/* navbar button */}
        <Meunbtn />

      </div>

      <div className="z-[4] bg-blur shadow-lg backdrop-blur-md h-full w-full top-0 absolute" />

    </div>
  )
}

export default Navbar