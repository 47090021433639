import { Link } from "react-router-dom";
import { icon } from "../constants/index"
import Hometitle from "./Hometitle"

const Contact = () => {

  const num = []
  for (let i = 0; i < 100; i++) {
    num.push(i)
  }

  return (
    <div id="contact" className="w-full mt-12 mb-0 xs:my-6 pt-6 xs:m-auto flex justify-center items-center animate__animated animate__fadeIn ease-in-out duration-500">

      {/* contact section container */}
      <div className="max-w-[310px] relative">

        {/* title */}
        <Hometitle title={"Get In Touch!"} subtitle={"Send me a message!"} />

        {/* container */}
        <div className="flex max-w-[310px] flex-wrap justify-center items-center w-full relative">

          {/* contact icon and name loop */}
          {icon.map((item) => (
            <Link to={item.link} key={item.id} className="z-[3] min-w-[250px] xs:min-w-[310px] py-2 xs:py-4 px-2 xs:px-12 mx-8 xs:mx-16 my-4 text-light font-bold flex justify-start items-center relative group ease-in-out duration-300" target="_blank">
              <div className="z-[-2] w-full text-base flex justify-start items-center">
                <span className="max-w-[50px] text-main text-[2.4rem] ml-4 p-4 flex justify-center">{item.iclass}</span>
                <h1 className="w-[0%] h-auto font-poppins ml-4 group-hover:w-[20%] text-[1.4rem] group-hover:text-main animate__animated group-hover:animate__fadeInLeft ease-in-out duration-200 group-hover:border-b-4 transition-all">{item.title}</h1>
                <div className="z-[-3] h-full w-full rounded-lg bg-textbg border-white border-[1px] backdrop-blur-md shadow-xl bottom-0 left-0 absolute" />
              </div>
            </Link>
          ))
          }
        </div>

        {/* background */}
        <div className="w-full h-full absolute top-[0%] left-[50%]">
          <div className="z-[-20] w-[14rem] h-[14rem] sm:w-[30rem] sm:h-[30rem] blur-2xl bg-gradient-to-bl border-white border-[0.2px] from-[#FFD01444] from-10% to-[#FFD01411] to-85% shadow-lg rounded-full top-[18%] left-[100%] xs:top-[14%] xs:left-[70%] md:top-[68.4%] md:left-[180%] absolute ease-in-out duration-200 animate-ping" />
          <div className="z-[-10] w-[6rem] h-[6rem] top-[77%] left-[-60%] scale-50 absolute grid grid-cols-10 ease-in-out duration-200 animate-ping">
            {num.map((item) => (
              <div key={item} className="z-[-10] w-[3px] h-[3px] bg-sub shadow-lg opacity-80 rounded-full col-span-1" />
            ))
            }
          </div>
        </div>

      </div>

    </div>
  )
}

export default Contact