import React from 'react'
import Title from '../../../../components/Title'
import { image } from '../../../../constants'
import Navbar from '../../../../components/Navbar'

const DanceArt = () => {
  return (
    <div>
      <Navbar work={true} path={"/graphic"} />

      <div className="mt-6 xs:mt-11 sm:mt-16 m-auto flex flex-wrap justify-center items-center w-[87%] animate__animated animate__fadeIn ease-in-out duration-500">

        <Title title={"Dance Art Hong Kong"} subtitle={"a collection of projects"} />

        <div className="grid grid-cols-2 md:grid-cols-10 gap-4 items-center justify-center max-w-[1250px] mx-6 md:mx-28 relative ease-in-out duration-300">

          <img className="w-full h-full col-span-2 row-span-2 md:col-span-8 md:row-span-1 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.dahk[0]} />
          <img className="w-full h-full col-span-1 row-span-2 md:col-span-2 md:row-span-1 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.dahk[2]} />
          <img className="w-full h-full col-span-1 row-span-1 md:col-span-4 md:row-span-1 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.dahk[1]} />
          <div className="w-full h-full col-span-1 row-span-1 md:col-span-6 md:row-span-1 rounded-lg flex justify-center items-center ease-in-out duration-300">
            <p className="z-[1] w-full p-0 md:p-4 rounded-lg text-sm text-light drop-shadow-lg text-justify tracking-tight max-h-[168px]">
              For the "Dance Art Hong Kong " project drama performance, I am offering design services for a poster, certificate, and backdrop.
            </p>
          </div>

        </div>

      </div>
    </div>
  )
}

export default DanceArt