import React from 'react'

const Hometitle = (props) => {
    return (
        <h1 className="my-16 w-full mx-auto font-poppins text-4xl text-center tracking-tight italic text-main font-semibold">
            {props.title}
            <span className="block font-poppins text-xs text-gray font-medium">
                {props.subtitle}
            </span>
        </h1>
    )
}

export default Hometitle