export const navLinks = [
  {
    num: "01.",
    id: "about",
    title: "About",
    key: "aboutKey"
  },
  {
    num: "02.",
    id: "work",
    title: "Work",
    key: "workKey"
  },
  {
    num: "03.",
    id: "contact",
    title: "Contact",
    key: "contactKey"
  },
];
export const logo = [
  {
    id: "HTML",
    link: "img/Logo/HTML.png",
  },
  {
    id: "CSS",
    link: "img/Logo/CSS.png",
  },
  {
    id: "JavaScript",
    link: "img/Logo/JavaScript.png",
  },
  {
    id: "React.js",
    link: "img/Logo/React.js.png",
  },
  {
    id: "Node.js",
    link: "img/Logo/Node.js.png",
  },
  {
    id: "npm",
    link: "img/Logo/npm.png",
  },
  {
    id: "jQuery",
    link: "img/Logo/jQuery.png",
  },
  {
    id: "Bootstrap",
    link: "img/Logo/Bootstrap.png",
  },
  {
    id: "TailwindCSS",
    link: "img/Logo/Tailwind.png",
  },
  {
    id: "Figma",
    link: "img/Logo/Figma.png",
  },
  {

    id: "Github",
    link: "img/Logo/Github.png",
  },
  {

    id: "Canva",
    link: "img/Logo/Canva.png",
  },
  {

    id: "Blender",
    link: "img/Logo/Blender.png",
  },
  {

    id: "Adobe Illustrator",
    link: "img/Logo/illustrator.png",
  },
  {

    id: "Adobe Photoshop",
    link: "img/Logo/photoshop.png",
  }
];
export const graphic = [
  {
    id: "cuhk",
    title: "The Chinese University of Hong Kong",
    link: "/img/Graphic/Graphic/img/graphic-15.png",
  },
  {
    id: "hkdj",
    title: "The Hong Kong Department of Justice",
    link: "/img/Graphic/Graphic/img/graphic-17.png",
  },
  {
    id: "hkaff",
    title: "Hong Kong Asian Film Festival",
    link: "/img/Graphic/Graphic/img/graphic-16.png",
  },
  {
    id: "dahk",
    title: "Dance Art Hong Kong",
    link: "/img/Graphic/Graphic/img/graphic-18.png",
  },
  {
    id: "florian",
    title: "Florian",
    link: "/img/Graphic/Graphic/img/graphic-13.png",
  },
  {
    id: "logo",
    title: "Logo Design",
    link: "/img/Graphic/Graphic/img/graphic-14.png",
  },
];
export const icon = [
  {
    id: "email",
    title: "Email",
    iclass: <i class="fa-solid fa-envelope" />,
    link: "mailto:iancheng29012@gmail.com"
  },
  {
    id: "whatsapp",
    title: "Whatsapp",
    iclass: <i class="fa-brands fa-square-whatsapp" />,
    link: "https://wa.me/85297788532"
  },
  {
    id: "linkedin",
    title: "Linkedin",
    iclass: <i class="fa-brands fa-linkedin" />,
    link: "https://www.linkedin.com/in/iancheng29012/",
  },
  {
    id: "github",
    title: "GitHub",
    iclass: <i class="fa-brands fa-github" />,
    link: "https://github.com/ian29012",
  }
];
export const frontend = [
  {
    id: "portfolio",
    title: "Portfolio",
    text: "A portfolio is a dynamic showcase of an individual's skills, accomplishments, featuring a collection of works and experiences. This particular portfolio has been built using React and Tailwind CSS, and is hosted on Cloudflare.",
    img: "img/FrontEnd/portfolio.png",
    link: ["https://iancheng.pages.dev/", "https://github.com/ian29012/IanCheng_Portfolio", "https://www.figma.com/file/rsf51JNQREnoW8zDsIMR40/Web-Portfolio?node-id=0%3A1&t=otmqaDS1Ov4kthlj-1"],
    tool: ["React", "TailwindCss", "JavaScript", "cloudflare"],
  },
  {
    id: "quiz",
    title: "The Geo Quiz",
    text: "Through a quiz format, this application prompts users with questions to test their knowledge of specific countries, while also sparking their curiosity to learn more about the topic.",
    img: "img/FrontEnd/Qiuz.png",
    link: ["https://todo-reactapp-2023.netlify.app/", "https://github.com/lorettarehm/todo-react-app", "https://todo-reactapp-2023.netlify.app/"],
    tool: ["HTML", "CSS", "JavaScript", "Bootstrap", "Netlify"],
  },
  {
    id: "weather",
    title: "Weather Dashboard",
    text: "Build a weather dashboard that will run in the browser and feature dynamically updated HTML and CSS with API.",
    img: "img/FrontEnd/Weather.png",
    link: ["https://ian29012.github.io/Weather-Dashboard/", "https://github.com/ian29012/Weather-Dashboard", ""],
    tool: ["HTML", "CSS", "JavaScript", "Weather API"],
  },
  {
    id: "scheduler",
    title: "Work Day Scheduler",
    text: "A simple calendar application that allows a user to save events for each hour of the day by modifying starter code. This app will run in the browser and feature dynamically updated HTML and CSS powered by jQuery.",
    img: "img/FrontEnd/Scheduler.png",
    link: ["https://ian29012.github.io/Work-Day-Scheduler/", "https://github.com/ian29012/Work-Day-Scheduler", ""],
    tool: ["HTML", "CSS", "JavaScript", "jQuery"],
  },
  {
    id: "team",
    title: "Team Profile Generator",
    text: "Using Node.js, this application will collect information about software engineering team members and generate an HTML webpage displaying a summary for each employee.",
    img: "img/FrontEnd/TeamGenerator.png",
    link: ["https://github.com/ian29012/Team-Profile-Generator", "https://github.com/ian29012/Team-Profile-Generator", ""],
    tool: ["HTML", "CSS", "JavaScript", "Node.js"],
  },
];
export const image =
{
  cuhk: ["/img/Graphic/HongKongChineseUniversity/HKCU-01.png",
    "/img/Graphic/HongKongChineseUniversity/HKCU-02.png",
    "/img/Graphic/HongKongChineseUniversity/HKCU-03.png",
    "/img/Graphic/HongKongChineseUniversity/HKCU-04.png",
  ],
  hkdj: ["/img/Graphic/TheHongKongDepartmentofJustice/HKDJ-01.png",
    "/img/Graphic/TheHongKongDepartmentofJustice/HKDJ-02.png",
    "/img/Graphic/TheHongKongDepartmentofJustice/HKDJ-03.png",
    "/img/Graphic/TheHongKongDepartmentofJustice/HKDJ-04.png",
  ],
  hkaff: ["/img/Graphic/HongKongAsianFilmFestival/HKAFF.jpg",
    "/img/Graphic/HongKongAsianFilmFestival/HKAFF2.jpg",
  ],
  dahk: ["/img/Graphic/DanceArtHongKong/backdrop.png",
    "/img/Graphic/DanceArtHongKong/certificate.png",
    "/img/Graphic/DanceArtHongKong/poster.png",
  ],
  florian: ["/img/Graphic/Florian/Florian-01.png",
    "/img/Graphic/Florian/Florian-02.png",
    "/img/Graphic/Florian/Florian-03.png",
    "/img/Graphic/Florian/Florian-04.png",
    "/img/Graphic/Florian/Florian-05.png",
    "/img/Graphic/Florian/Florian-06.png",
  ],
  logo: ["/img/Graphic/Logo/logo-01.png",
    "/img/Graphic/Logo/logo-02.png",
    "/img/Graphic/Logo/logo-03.png",
    "/img/Graphic/Logo/logo-04.png",
  ]
}