import { frontend } from '../../constants';
import Title from '../../components/Title';
import Navbar from '../../components/Navbar';
import { Link } from 'react-router-dom';

function FrontEnd() {

  // go to top
  const handleClick = () => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 200);
  };

  return (
    <div>
      <Navbar work={true} path={"/"} />

      {/* frontend section container */}
      <div id="frontend" className="mt-6 xs:mt-11 sm:mt-16 flex flex-wrap justify-center items-center w-full animate__animated animate__fadeIn ease-in-out duration-500">

        {/* frontend title */}
        <Title title={"FrontEnd Dev"} subtitle={"a collection of projects"} />

        {/* frontend container */}
        <div className='z-[3] w-[91%] md:w-[80%] lg:w-[71%] xl:w-[50%] gap-8 h-auto m-auto flex flex-wrap items-center justify-center' onClick={handleClick}>

          {/* loop for each project */}
          {frontend.map((item) => (
            <Link to={item.link[0]} key={item.id} target="_blank" className="z-[10]">

              <div key={item.id} className='z-[4] w-[300px] xs:w-[350px] h-[443px] flex flex-col items-center text-light ease-in-out duration-300 relative group'>

                {/* project title */}
                <span className='z-[3] font-Sofia py-3 mt-3 p-1 w-[95%] font-black text-[1.7rem] min-h-[27px] leading-[8px] xs:leading-[-18px] group-hover:text-main text-start ease-in-out duration-300'>{item.title}</span>

                {/* project tool */}
                <div className="flex flex-row justify-start items-center w-[95%] h-auto my-2 pb-2 border-b-2 border-light">
                  {item.tool.map((toolItem) =>
                    <span key={toolItem} className='z-[3] font-poppins px-1.5 p-0.5 mr-0.5 font-nomral text-xs leading-[-8px] xs:leading-[18px] text-white bg-light hover:bg-main rounded-full text-start place-self-start ease-in-out duration-300'>{toolItem}</span>
                  )}
                </div>

                {/* repo */}
                <div className="flex flex-row justify-start items-center w-[95%] h-auto pb-2">
                    <Link to={item.link[0]} key={item.link[0]} target="_blank" className='z-[3] font-poppins px-2 p-1 mr-2 font-semibold text-xs leading-[-8px] xs:leading-[18px] text-main border-[3px] borader-main hover:bg-main hover:text-white hover:translate-y-[-2px] rounded-md text-start place-self-start ease-in-out duration-300'>
                      Live
                    </Link>
                    <Link to={item.link[1]} key={item.link[1]} target="_blank" className='z-[3] font-poppins px-2 p-1 mr-2 font-semibold text-xs leading-[-8px] xs:leading-[18px] text-main border-[3px] borader-main hover:bg-main hover:text-white hover:translate-y-[-2px] rounded-md text-start place-self-start ease-in-out duration-300'>
                      Repo
                    </Link>
                    <Link to={item.link[2]} key={item.link[2]} target="_blank" className='z-[3] font-poppins px-2 p-1 mr-2 font-semibold text-xs leading-[-8px] xs:leading-[18px] text-main border-[3px] borader-main hover:bg-main hover:text-white hover:translate-y-[-2px] rounded-md text-start place-self-start ease-in-out duration-300'>
                      Figma
                    </Link>
          
                </div>

                {/* project image */}
                <div className="z-[5] w-full min-h-[210px] xs:min-h-[200px] py-1 flex justify-center items-center">
                  <img className='z-[1] w-[93%] h-[170px] xs:w-[92%] xs:min-h-[190px] shadow-inner rounded-lg absolute ease-in-out duration-300' src={process.env.PUBLIC_URL + `${item.img}`} alt="" />
                </div>

                {/* project description */}
                <p className='z-[3] w-[90%] font-normal pt-2 text-xs font-poppins text-justify tracking-tight leading-[-8px] group-hover:text-main'>{item.text}</p>

                {/* card background */}
                <div className="z-[-4] h-full w-full rounded-lg bg-textbg border-white group-hover:bg-main/10 border-[1px] backdrop-blur-md shadow-xl bottom-0 left-0 absolute ease-in-out duration-300" />

              </div>

            </Link>
          ))}
        </div>

      </div>

    </div >

  )
}

export default FrontEnd