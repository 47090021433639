import React from 'react'
import { Link } from 'react-router-dom';

function Cancel(props) {

  // back to work section at home page
  const handleClick = () => {
    setTimeout(() => {
      window.location.hash = props.id;
    }, 200);
  };

  return (
    <>
      <Link to={props.path} className="z-[99] flex justify-center animate__animated animate__fadeIn ease-in-out duration-500" onClick={handleClick}>
        <i className="text-2xl ml-[20px] xs:ml-[30px] sm:ml-[40px] text-main fa-regular fa-circle-xmark" />
      </Link>
    </>
  )
}

export default Cancel