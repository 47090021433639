import React from 'react'
import Title from '../../../../components/Title'
import { image } from '../../../../constants'
import Navbar from '../../../../components/Navbar'

const HKAFF = () => {
  return (
    <div>
      <Navbar work={true} path={"/graphic"} />

      <div className="mt-6 xs:mt-11 sm:mt-16 m-auto flex flex-wrap justify-center items-center w-[87%] animate__animated animate__fadeIn ease-in-out duration-500">

        <Title title={"Hong Kong Asian Film Festival"} subtitle={"a collection of projects"} />

        <div className="grid grid-cols-4 gap-4 items-center justify-center max-w-[1250px] mx-6 md:mx-28 relative">

          <img className="w-full h-full col-span-2 row-span-1 md:row-span-2 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.hkaff[0]} />
          <img className="w-full h-full col-span-2 row-span-1 md:row-span-2 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.hkaff[1]} />
          <div className="w-full h-full col-span-4 md:px-56 md:col-span-4 row-span-1 rounded-lg flex justify-center items-center ease-in-out duration-300">
            <p className="w-full p-4 rounded-lg text-sm text-light drop-shadow-lg text-justify tracking-tight max-h-[168px]">
              For the "Hong Kong Asian Film Festival" project, the client requested a backdrop and five 50mm die-cut foam board pieces.
              I designed the model and cut 10 pieces of 5mm foam board, and also assembled each foam board with glue.
            </p>
          </div>

        </div>


      </div>
    </div>
  )
}

export default HKAFF