import React from 'react'
import Title from '../../../../components/Title'
import { image } from '../../../../constants'
import Navbar from '../../../../components/Navbar'

const Justice = () => {
  return (
    <div>
      <Navbar work={true} path={"/graphic"} />

      <div className="mt-6 xs:mt-11 sm:mt-16 m-auto flex flex-wrap justify-center items-center w-[87%] animate__animated animate__fadeIn ease-in-out duration-500">

        <Title title={"The Hong Kong Department of Justice"} subtitle={"a collection of projects"} />

        <div className="grid grid-cols-5 md:grid-cols-9 gap-4 items-center justify-center max-w-[1250px] mx-6 md:mx-28 relative">

          <img className="w-full h-full col-span-3 row-span-1 md:col-span-4 md:row-span-3 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.hkdj[0]} />
          <img className="w-full h-full col-span-2 row-span-1 md:col-span-3 md:row-span-3 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.hkdj[2]} />
          <img className="w-full h-full col-span-2 row-span-1 md:col-span-2 md:row-span-1 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.hkdj[3]} />
          <img className="w-full h-full col-span-3 row-span-1 md:col-span-2 md:row-span-2 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.hkdj[1]} />
          <div className="w-full h-full col-span-5 row-span-1 px-0 md:px-28 md:col-span-9 md:row-span-2 rounded-lg flex justify-center items-center ease-in-out duration-300">
            <p className="z-[1] w-full p-4 rounded-lg text-sm text-light drop-shadow-lg text-justify tracking-tight max-h-[168px]">
              Designing a 10 meters (length) by 4 meters (height) vinyl backdrop with a projector screen hole was a new challenge for me.
              I had to consider factors such as bleeding and overlap for the vinyl, as well as the depth of the wooden stand.
            </p>
          </div>

        </div>

      </div>
    </div>
  )
}

export default Justice