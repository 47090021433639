import { Link } from 'react-router-dom'
import { logo } from '../constants/index'
import Hometitle from './Hometitle'

const About = () => {

  return (
    <div id="about" className="mt-12 xs:mt-24 pt-10 w-full flex justify-center items-center animate__animated animate__fadeIn ease-in-out duration-500 relative">

      {/* about section container */}
      <div className="w-full flex flex-wrap justify-center">

        {/* title */}
        <Hometitle title={"About Me"} subtitle={"Information regarding myself"} />

        {/* container */}
        <div className="max-w-[320px] sm:max-w-[430px] md:max-w-[1530px] grid grid-cols-2 md:flex md:flex-warp justify-center justify-items-center items-center">

          {/* 100project / 3yesrs */}
          <img className="w-auto h-[3.8rem] sm:h-[4.6rem] mb-5 sm:ml-7 md:m-0 md:mr-14 md:ml-6 col-span-1 ease-in-out duration-300" src={process.env.PUBLIC_URL + "/img/about/100+.png"} alt="" />
          <img className="w-auto h-[3.8rem] sm:h-[4.6rem] mb-5 sm:mr-7 md:m-12 col-span-1 block md:hidden ease-in-out duration-300" src={process.env.PUBLIC_URL + "/img/about/3years.png"} alt="" />

          {/* context */}
          <p className="z-[-2] xs:w-[450px] h-auto col-span-2 md:col-span-3 md:row-span-1 text-justify text-xs my-4 sm:my-0 p-6 sm:p-10 xs:text-base antialiased tracking-tight text-light ease-in-out duration-300 relative">With a solid background of 3 years graphic design experience, I bring valuable insights into user experience and a keen eye for creating intuitive layouts for backdrops, posters, and leaflets. <br /><br />
            I have collaborated with prestigious organizations such as The Chinese University of Hong Kong, The Hong Kong Department of Justice, The Hong Kong Asian Film Festival, and Dance Art Hong Kong on various visual projects. <br /><br />
            Also hold a certificate in Front-End Web Development from Trilogy Education Services Boot Camp and a High Diploma in Communication Design from Hong Kong Design Institute. <br /><br />
            With a passion for implementing innovative graphic solutions for users, I excel in creative thinking and teamwork. I am eager to apply my graphic knowledge to advance my career as a Front-End Developer.
            {/* context background */}
            <div className="z-[-3] mx-2 rounded-lg bg-textbg border-white border-[1px] backdrop-blur-md shadow-xl h-full w-full bottom-0 left-0 absolute ease-in-out duration-300" />
          </p>

          {/* 3yesrs */}
          <img className="w-auto h-[3.8rem] sm:h-[4.6rem] mb-5 sm:mr-7 md:m-0 md:ml-14 md:mr-6 md:px-[1.63rem] col-span-1 hidden md:block ease-in-out duration-300" src={process.env.PUBLIC_URL + "/img/about/3years.png"} alt="" />

        </div>

        {/* button container */}
        <div className="w-full flex flex-wrap xs:flex-nowrap justify-around items-center mx-[60rem] my-4 xs:my-12 font-poppins text-md tracking-tight text-white font-semibold ease-in-out duration-300">

          {/* review container */}
          <Link to="https://www.canva.com/design/DAFaF4cU0p4/bqPye79ULVIH6MZvyVv4NQ/view?utm_content=DAFaF4cU0p4&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink" target="_blank" className="xs:m-2 w-48 h-11 scale-75 xs:scale-100 bg-main hover:bg-mainsub rounded-lg flex justify-around items-center hover:translate-y-[-5px] ease-in-out duration-300">
            <div>
              <span className="mr-3">Review My Cv</span>
              <i className="fa-solid fa-magnifying-glass"></i>
            </div>
          </Link>

          {/* download container */}
          <Link to="https://drive.google.com/uc?export=download&id=1qOgchUuJRVKX89VOj8oQZW9chW1GwPYC" target="_blank" className="m-2 w-48 h-11 scale-75 xs:scale-100 bg-main hover:bg-mainsub rounded-lg flex justify-around items-center hover:translate-y-[-5px] ease-in-out duration-300">
            <div>
              <span className="mr-3">Download My Cv</span>
              <i className="fa-solid fa-download"></i>
            </div>
          </Link>

        </div>

        {/* skill title */}
        <Hometitle title={"Expertise"} subtitle={"Skill Set"} />

        {/* skill container */}
        <div className='flex flex-wrap w-[90%] xs:w-[80%] h-auto m-auto items-center justify-center'>

          {/* skill icon and name loop */}
          {logo.map((item) => (
            <div key={item.id} className='flex w-14 flex-col items-center xs:m-1 h-22 xs:h-auto xs:mx-4 scale-75 xs:scale-100 ease-in-out duration-300 hover:translate-y-[-5px]'>
              <img className='w-12 h-auto m-1' src={process.env.PUBLIC_URL + `${item.link}`} alt="" />
              <span className='text-light w-auto font-semibold py-3 text-xs font-poppins text-center'>{item.id}</span>
            </div>
          ))}
        </div>

      </div>

      {/* background */}
      <div className="max-w-7xl h-full top-[50%] left-[50%]">
        <div className="z-[-10] sm:w-[9rem] sm:h-[9rem] xs:w-[16rem] xs:h-[16rem] w-[12rem] h-[12rem] blur-[1px] bg-gradient-to-bl border-white border-[0.2px] from-[#e3e3e311] from-20% to-[#FFD014] to-70% shadow-lg rounded-full top-[15.5%] left-[-30%] xs:top-[13%] xs:left-[-28%] ss:top-[8%] ss:left-[-18%] sm:top-[48.5%] sm:left-[8%] md:top-[10%] md:left-[31%] absolute ease-in-out duration-200 animate-ping" />
        <div className="z-[-10] sm:w-[4.9rem] sm:h-[4.9rem] xs:w-[6rem] xs:h-[6rem] w-[4.5rem] h-[4.5rem] blur-[0.5px] sm:blur-[1px] bg-gradient-to-bl border-white border-[0.2px] from-[#DAE7FB22] from-20% to-[#758E83] to-70%  shadow-lg rounded-full top-[49.6%] left-[78%] xs:top-[46.5%] xs:left-[81.5%] ss:top-[49.5%] ss:left-[81%] sm:top-[18.5%] sm:left-[76%] md:top-[54%] md:left-[62%] absolute ease-in-out duration-200 animate-ping" />
        <div className="z-[-10] md:w-[14rem] md:h-[14rem] w-[22rem] h-[22rem] blur-2xl bg-gradient-to-bl border-white border-[0.2px] from-[#FFD01444] from-10% to-[#FFD01411] to-85% shadow-lg rounded-full top-[60%] left-[-54%] xs:top-[58%] xs:left-[-44%] sm:top-[75%] sm:left-[75%]  md:top-[70%] md:left-[5%] absolute ease-in-out duration-200 animate-ping" />
      </div>

    </div >
  )
}

export default About
