import { graphic } from '../../constants';
import { Link } from 'react-router-dom';
import Title from '../../components/Title';
import Navbar from '../../components/Navbar';

function Graphic() {

  // go to top
  const handleClick = () => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 200);
  };

  return (
    <div>
      <Navbar work={true} path={"/"} />

      {/* graphic section container */}
      <div id="grahpic" className="w-full mt-6 xs:mt-11 sm:mt-16 flex flex-wrap justify-center items-center animate__animated animate__fadeIn ease-in-out duration-500">

        {/* graphic title */}
        <Title title={"Graphic Design"} subtitle={"a collection of projects"} />

        {/* project container */}
        <div className='z-[3] w-[91%] md:w-[80%] lg:w-[71%] xl:w-[50%] gap-6 h-auto m-auto flex flex-wrap items-center justify-center' onClick={handleClick}>

          {/* loop for each project */}
          {graphic.map((item) => (
            <Link to={`/${item.id}`} className="z-[10]">
              <div key={item.id} className='z-[4] w-[300px] h-[225px] xs:w-[410px] xs:h-[295px] flex flex-col items-center text-light ease-in-out duration-300 relative group'>
                <span className='z-[3] font-Sofia py-4 w-[95%] font-black xs:text-[1.7rem] min-h-[27px] leading-[8px] xs:leading-[18px] group-hover:text-main xs:bottom-[26px] xs:left-[26px] text-start drop-shadow-md ease-in-out duration-300'>{item.title}</span>
                <img className='z-[1] w-[95%] min-h-min rounded-lg ease-in-out duration-300' src={process.env.PUBLIC_URL + `${item.link}`} alt="" />
                <div className="z-[-4] h-full w-full rounded-lg bg-textbg border-white group-hover:bg-main/10 border-[1px] backdrop-blur-md shadow-xl bottom-0 left-0 absolute ease-in-out duration-300" />
              </div>
            </Link>
          ))}

        </div>
      </div>

    </div >
  )
}

export default Graphic