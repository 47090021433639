import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { About, Home, Contact, Navbar, Workbar, Work, Email, Footer } from './components';
import { FrontEnd, Graphic, CUHK, DanceArt, Florian, HKAFF, Justice, Logo } from './Work';
import 'animate.css';


function HomeAbout() {
  return (
    <>
      <Navbar />
      <Home />
      <About />
      <Work />
      <Contact />
    </>
  );
}

function App() {
  return (
    <div className='flex justify-center flex-wrap w-full overflow-x-clip'>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeAbout />} />
          <Route path="/frontend" element={<FrontEnd />} />
          <Route path="/graphic" element={<Graphic />} />
          <Route path="/cuhk" element={<CUHK />} />
          <Route path="/dahk" element={<DanceArt />} />
          <Route path="/florian" element={<Florian />} />
          <Route path="/hkaff" element={<HKAFF />} />
          <Route path="/hkdj" element={<Justice />} />
          <Route path="/logo" element={<Logo />} />
        </Routes>
        {/* <Email /> */}
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

