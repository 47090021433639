import { Link } from 'react-router-dom';

const Work = () => {

  // go to top
  const handleClick = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  };

  // loop dot for background squre
  const num = []
  for (let i = 0; i < 100; i++) {
    num.push(i)
  }

  // loop dot for background square
  const num2 = []
  for (let i = 0; i < 5; i++) {
    num2.push(i)
  }

  return (
    <div id="work" className="w-[86%] pt-12 xs:my-12 xs:pt-24 md:pt-36 flex justify-center items-center relative animate__animated animate__fadeIn ease-in-out duration-500">

      {/* work section container */}
      <div className=" w-full max-w-[1100px] my-3 sx:mx-9 flex flex-wrap justify-around items-center relative ease-in-out duration-300">

        {/* graphic container */}
        <div className="z-[10] w-[100%] min-h-[450px] xs:max-w-[510px] xs:min-h-[540px] p-6 xs:p-11 xs:pr-32 mx-4 my-5 text-base tracking-tight text-justify text-light relative ease-in-out duration-300">

          {/* graphic title */}
          <h1 className="font-poppins w-[60%] xs:w-72 h-auto py-3 text-[2.25rem] xs:text-[3.25rem] leading-[35px] xs:leading-[60px] tracking-tight text-mainsub font-bold ease-in-out duration-300">Graphic Experience</h1>

          {/* graphic context */}
          <p className="font-poppins w-auto min-h-[240px] xs:w-72 xs:h-auto py-8 text-xs xs:text-sm leading-[23px] tracking-tight text-light font-normal ease-in-out duration-300">
            Collaborate with organizations including : <br />
            <br />
            The Chinese University of Hong Kong<br />
            The Hong Kong Department of Justice<br />
            The Hong Kong Asian Film Festival<br />
            Dance Art Hong Kong<br />
            <br />
            on Visual Projects.
          </p>

          {/* btn to graphic section */}
          <Link to="/graphic" className="z-[10] w-[60%] xs:w-44 h-11 my-2 bg-main hover:bg-mainsub rounded-lg flex justify-around items-center font-poppins text-md tracking-tight text-white font-semibold ease-in-out duration-300 hover:translate-y-[-5px]" onClick={handleClick}>
            <div className="z-[10]">
              <span className="z-[10] mr-3">Read More</span>
              <i className="z-[10] fa-solid fa-arrow-right"></i>
            </div>
          </Link>

          {/* graphic blur background */}
          <div className="z-[-4] rounded-lg bg-textbg border-white border-[1px] backdrop-blur-md shadow-xl h-full w-full bottom-0 left-0 absolute ease-in-out duration-300" />
        </div>

        {/* frontend container */}
        <div className="z-[10] w-[100%] min-h-[450px] xs:max-w-[510px] xs:min-h-[540px] p-6 xs:p-11 xs:pr-32 mx-4 my-5 text-base text-justify tracking-tight text-light relative ease-in-out duration-300">

          {/* frontend title */}
          <h1 className="font-poppins w-[85%] xs:w-72 h-auto py-3 text-[2.25rem] xs:text-[3.25rem] leading-[35px] xs:leading-[60px] tracking-tight text-mainsub font-bold ease-in-out duration-300">Front-End stuff I build</h1>

          {/* frontend context */}
          <p className="font-poppins w-auto min-h-[240px] xs:w-72 xs:h-auto py-8 text-xs xs:text-sm leading-[23px] tracking-tight text-light font-normal ease-in-out duration-300">
            Some open-source projects including : <br />
            <br />
            Web applications<br />
            Experimental<br />
            <br />
          </p>

          {/* btn to frontend section */}
          <Link to="/frontend" className="z-[10] w-[60%] xs:w-44 h-11 my-2 bg-main hover:bg-mainsub rounded-lg flex justify-around items-center font-poppins text-md tracking-tight text-white font-semibold ease-in-out duration-300 hover:translate-y-[-5px]" onClick={handleClick}>
            <div className="z-[10]">
              <span className="z-[10] mr-3">Read More</span>
              <i className="z-[10] fa-solid fa-arrow-right"></i>
            </div>
          </Link>

          {/* frontend blur background */}
          <div className="z-[-4] rounded-lg bg-textbg border-white border-[1px] backdrop-blur-md shadow-xl h-full w-full bottom-0 left-0 absolute ease-in-out duration-300" />
        </div>

      </div>

      {/* backdrop */}
      <div className="max-w-7xl h-full top-[50%] left-[50%]">
        <div className="z-[-10] sm:w-[12rem] sm:h-[12rem] xs:w-[16rem] xs:h-[16rem] w-[12rem] h-[12rem] blur-[1px] bg-gradient-to-bl border-white border-[0.2px] from-[#e3e3e311] from-20% to-[#FFD014] to-70% shadow-lg rounded-full top-[15.5%] left-[-30%] xs:top-[0%] xs:left-[-20%] sm:top-[-3%] sm:left-[0%] md:top-[15%] md:left-[10%] absolute ease-in-out duration-200 animate-ping" />
        <div className="z-[-10] xs:w-[7rem] xs:h-[7rem] w-[4.5rem] h-[4.5rem] blur-[0.5px] bg-gradient-to-bl border-white border-[0.2px] from-[#DAE7FB22] from-20% to-[#758E83] to-70%  shadow-lg rounded-full top-[49.6%] left-[78%] xs:top-[53.4%] xs:left-[80%] md:top-[86%] sm:top-[93%] sm:left-[69%] lg:left-[79%] absolute ease-in-out duration-200 animate-ping" />
        <div className="z-[-10] w-[6rem] h-[6rem] top-[100%] left-[84%] md:left-[75%] absolute grid grid-cols-10 ease-in-out duration-200 animate-ping">
          {num.map((item) => (
            <div key={item} className="z-[-10] w-[3px] h-[3px] bg-sub shadow-lg rounded-full col-span-1" />
          ))
          }
        </div>
        <div className="z-[-10] w-[6rem] h-[0.5rem] top-[110%] md:top-[115%] left-[61%] xs:left-[71%] md:left-[65%] absolute grid grid-cols-5 ease-in-out duration-200 animate-ping">
          {num2.map((item) => (
            <div key={item} className="z-[-10] w-[10px] h-[10px] border-sub border-[2.6px] shadow-lg rounded-full col-span-1" />
          ))
          }
        </div>
      </div>

    </div>

  )
}

export default Work