import React from 'react'
import Title from '../../../../components/Title'
import { image } from '../../../../constants'
import Navbar from '../../../../components/Navbar'

const CUHK = () => {
  return (
    <div>
      <Navbar work={true} path={"/graphic"} />

      <div className="mt-6 xs:mt-11 sm:mt-16 m-auto flex flex-wrap justify-center items-center w-[87%] animate__animated animate__fadeIn ease-in-out duration-500">

        <Title title={"Hong Kong Chinese University"} subtitle={"a collection of projects"} />

        <div className="grid grid-cols-5 sm:grid-cols-9 gap-4 items-center justify-center max-w-[1250px] mx-8 md:mx-28 ease-in-out duration-300">

          <img className="w-full h-full col-span-5 sm:col-span-4 row-span-2 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.cuhk[0]} />
          <img className="w-full h-full col-span-5 row-span-1 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.cuhk[1]} />
          <img className="w-full h-full col-span-2 sm:row-span-1 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.cuhk[3]} />
          <img className="w-full h-full col-span-3 row-span-2 rounded-lg shadow-lg ease-in-out duration-300" src={process.env.PUBLIC_URL + image.cuhk[2]} />
          <p className="w-auto h-auto justify-self-center row-start-3 m-2 col-span-5 sm:col-span-6 row-span-1 text-sm text-light drop-shadow-lg tracking-tight text-justify ease-in-out duration-300">
            I am excited and grateful for the chance to collaborate with The Chinese University of Hong Kong.<br />
            Specifically, the MSc in Management department at CUHK entrusted me with designing a vinyl backdrop and podium for their speech event.
          </p>

        </div>


      </div>
    </div>
  )
}

export default CUHK