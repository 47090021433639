import React from 'react'

const Footer = () => {
  return (
    <div>
      <div className="mt-36 mb-10 m-auto max-w-[400px] flex flex-wrap justify-center items-center w-full text-sm font-questrial text-main">

        <p>Designed & Created by Ian Cheng</p>
        <p>Copyright © 2023 Ian Cheng All Rights Reserved</p>

      </div>
    </div>
  )
}

export default Footer